import React, { useState, useEffect, useCallback, useRef } from "react";
import { withAlert } from "react-alert";
import "./ExplainCase.scss";
import http from "../../../../utils/http";
import dateFormat from "dateformat";
import * as Icon from "react-feather";

import environment from "../../../../utils/environment";
import axios from "axios";

import { motion } from "framer-motion";

const ExplainCase = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [userPromt, setUserPrompt] = useState("");
  const [aiResponse, setAiResponse] = useState("");
  const [showExamples, setShowExamples] = useState(true);
  const inputRef = useRef(null);
  const topOfContainer = useRef(null);

  const scrollToTop = () => {
    const options = { behavior: "smooth", block: "center", inline: "start" };

    if (topOfContainer.current) {
      topOfContainer.current.scrollIntoView(options);
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []); // Empty dependency array ensures the effect only runs once on mount

  const handleChange = (event) => {
    setInputValue(event.target.textContent);
  };

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.innerText = "";
    }
  };

  const chatBot = async () => {
    try {
      setLoading(true);
      console.log("inputValue", inputValue);
      setShowExamples(false);

      setUserPrompt(inputValue);

      const response = await http.post(
        `${environment.resolveApi().rest}/gpt/type_of_lawyer`,
        {
          caseSituation: inputValue,
        }
      );

      setAiResponse(response);
      clearInput();
      setInputValue("");
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onContinue = () => {
    // save to localstorage
    const dataToSave = {
      date: dateFormat(new Date(), "mmmm dd"),
      pathname: "recommendation/criteria",
      city: null,
      status: "pending",
      service_request: aiResponse.lawType,
      message: userPromt,
      aiResponse: aiResponse.result,
      criteria: [],
      urgency: null,
      availability: [],
      income: null,
    };

    localStorage.setItem("search_save", JSON.stringify(dataToSave));

    props.history.push({
      pathname: `/recommendation/criteria`,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setUserPrompt("");
    setAiResponse("");
    chatBot();

    // Perform your form submission logic here
    console.log("Form submitted");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();

      if (!inputValue) {
        props.alert.error("Please enter in your case details.");
        return;
      }
      handleSubmit(event);
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ backgroundColor: "#FFF" }}
    >
      <div>
        <div
          className=""
          ref={topOfContainer}
          style={{ width: 100, height: 10 }}
        ></div>
        <div className="ccSearch__header">
          <a href="https://counselconnect.ca/">
            <img
              className="lawyer-search-navbar-logo"
              src={require("../images/cc-search-logo--blue.svg")}
              alt="counsel-connect-log"
            />
          </a>
        </div>

        <div className="explainCase__content">
          <div className="explainCase__content__form">
            <>
              <h4 className="explainCase__content__title">
                Please explain the situation you are dealing with
              </h4>

              <div className="explainCase__content__chat">
                {showExamples && (
                  <div className="explainCase__content__chatExamplesContainer">
                    <div className="explainCase__content__chatExamples">
                      <Icon.Sun color="#6a7a89" />
                      <span className="explainCase__content__chatExamples__txt">
                        Examples
                      </span>
                    </div>
                    <div className="explainCase__content__chatExamplesCards">
                      <div
                        className="explainCase__content__chatExamples__card"
                        onClick={() => {
                          inputRef.current.innerText =
                            "Need help drafting contract for my condo lease.";

                          setInputValue(
                            "Need help drafting contract for my condo lease."
                          );
                        }}
                      >
                        <span className="explainCase__content__chatExamples__card__txt">
                          “Need help drafting contract for my condo lease.”
                        </span>
                        <Icon.ArrowRight
                          color="#FFF"
                          className="explainCase__content__chatExamples__card__arrowRightIcon"
                        />
                      </div>
                    </div>

                    <div
                      className="explainCase__content__chatExamples__card"
                      onClick={() => {
                        inputRef.current.innerText =
                          "Dealing with a divorce, not sure what to do .";

                        setInputValue(
                          "Dealing with a divorce, not sure what to do ."
                        );
                      }}
                    >
                      <span className="explainCase__content__chatExamples__card__txt">
                        “Dealing with a divorce, not sure what to do .”
                      </span>
                      <Icon.ArrowRight
                        color="#FFF"
                        className="explainCase__content__chatExamples__card__arrowRightIcon"
                      />
                    </div>

                    <div
                      className="explainCase__content__chatExamples__card"
                      onClick={() => {
                        inputRef.current.innerText =
                          "Need help incorporating my business.";

                        setInputValue("Need help incorporating my business.");
                      }}
                    >
                      <span className="explainCase__content__chatExamples__card__txt">
                        “Need help incorporating my business.”
                      </span>
                      <Icon.ArrowRight
                        color="#FFF"
                        className="explainCase__content__chatExamples__card__arrowRightIcon"
                      />
                    </div>
                  </div>
                )}

                {userPromt && (
                  <div className="explainCase__content__chat__msg">
                    <img src={require("../images/icon_user_chat.svg")} />
                    <span className="explainCase__content__chat__msg__txt">
                      {userPromt}
                    </span>
                  </div>
                )}

                {loading && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{ backgroundColor: "#f6f0f0" }}
                  >
                    <div className="explainCase__content__chat__msg--loading">
                      <img src={require("../images/icon_gpt_chat.svg")} />
                      <span className="explainCase__content__chat__msg__txt">
                        Typing...
                      </span>
                    </div>
                  </motion.div>
                )}

                {aiResponse ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{}}
                  >
                    <>
                      <div className="explainCase__content__chat__msg">
                        <img src={require("../images/icon_gpt_chat.svg")} />
                        <span className="explainCase__content__chat__msg__txt">
                          {aiResponse.result}
                        </span>
                      </div>

                      <div className="explainCase__content__chat__result">
                        <span className="explainCase__content__chat__result__txt">
                          CounselConnect will match you with a top-rated{" "}
                          {aiResponse.lawType} Lawyer
                        </span>
                        <div
                          className="explainCase__content__chat__result__continueBtn"
                          onClick={onContinue}
                        >
                          <span className="explainCase__content__chat__result__continueBtn__txt">
                            Continue
                          </span>
                          <Icon.ArrowRight color="#FFF" />
                        </div>

                        <span className="explainCase__content__chat__result__orTxt">
                          Or search again.
                        </span>
                      </div>
                    </>
                  </motion.div>
                ) : (
                  <div className="nomsgSpace"></div>
                )}

                {!loading && (
                  <div className="explainCase__inputContainer">
                    <div
                      className="demo"
                      contentEditable="true"
                      onInput={handleChange}
                      data-placeholder="Explain situation..."
                      onKeyDown={handleKeyPress}
                      ref={inputRef}
                    ></div>

                    <div className="featherIcon__sendContainer"></div>
                    <div
                      className={`featherIcon__send__iconContainer
                        ${inputValue &&
                        "featherIcon__send__iconContainer--active"
                        }`}
                      onClick={() => {
                        if (!inputValue) {
                          props.alert.error(
                            "Please enter in your case details."
                          );
                          return;
                        } else {
                          setUserPrompt("");
                          setAiResponse("");
                          chatBot();
                        }
                      }}
                    >
                      <Icon.Send className="featherIcon__send" />
                    </div>
                  </div>
                )}
              </div>
            </>
          </div>
          <div className="explainCase__footer">
            <img
              className="explainCase__footer__poweredbyIcon"
              src={require("./icon_poweredby.svg")}
              alt="counsel-connect-log"
            />

            <span className="explainCase__footer__disclaimer">
              Please don’t provide any sensitive information pertaining to your
              case.
            </span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default withAlert()(ExplainCase);
